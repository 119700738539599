import React, { useEffect, useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import Firebase from 'firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import GridContainer from "./components/Grid/GridContainer.js";
import GridItem from "./components/Grid/GridItem.js";
import Card from "./components/Card/Card.js";
import CardBody from "./components/Card/CardBody.js";
import CardHeader from './components/Card/CardHeader.js';
import CardFooter from './components/Card/CardFooter.js';
import CardText from './components/Card/CardText.js';
import SnackbarContent from "./components/Snackbar/SnackbarContent.js";

import Button from "./components/CustomButtons/Button.js";
import Favorite from "@material-ui/icons/Favorite";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PinDropSharpIcon from "@material-ui/icons/PinDropSharp";
import MonetizationOnSharpIcon from "@material-ui/icons/MonetizationOnSharp";
import ChatSharpIcon from "@material-ui/icons/ChatSharp";
import GradeSharpIcon from "@material-ui/icons/GradeSharp";
import CallIcon from '@material-ui/icons/Call';
import Modal from '@material-ui/core/Modal';
import Place from "@material-ui/icons/Place";

import styles from "./assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";

import { MessageLeft, MessageRight } from "./components/Chat/Message.js";
import { TextInput } from "./components/Chat/TextInput.js";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Rating from '@material-ui/lab/Rating';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import DoneIcon from '@material-ui/icons/Done';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
const useStyles = makeStyles(styles);

const firebaseConfig = {
  apiKey: "AIzaSyDSaYuHlpFznfk_nhTcitcoGG-8QUGK1ec",
  authDomain: "onroad3-e593b.firebaseapp.com",
  databaseURL: "https://onroad3-e593b-default-rtdb.firebaseio.com",
  projectId: "onroad3-e593b",
  storageBucket: "onroad3-e593b.appspot.com",
  messagingSenderId: "587064425563",
  appId: "1:587064425563:web:99aac8f9a9a8b7cae9137e",
  measurementId: "G-5VFLZ6SK9T"
}
Firebase.initializeApp(firebaseConfig);

const App = () => {
  const [deliveryGuyLocation, setDeliveryGuyLocation] = useState(null);
  const [destinationLocation, setDestinationLocation] = useState(null);
  const [isCustomerAdjustedLocation, setIsCustomerAdjustedLocation] = useState(null);
  const [deliveryGuyLocationLiveTracking, setDeliveryGuyLocationLiveTracking] = useState(null);
  const [markerRef, setMarkerRef] = useState(null);
  const [chatMessages, setChatMessages] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [remainingDistance, setRemainingDistance] = useState(0);
  const [bottomTabValue, setBottomTabValue] = React.useState(0);
  const [deliveryReviewStars, setDeliveryReviewStars] = React.useState(5);
  const [deliveryReviewNote, setDeliveryReviewNote] = React.useState("Thank you very much for the service");
  const labels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
  };
  const [hover, setHover] = React.useState(-1);
  const [recorded, setRecorded] = React.useState(false);
  
  const queryParams = new URLSearchParams(window.location.search);
  const phones = ['8622878740', '5104021834'];//queryParams.get('cid').split(",");
  const deliveryGuyPhone = phones[0]
  const destinationPhone = phones[1]
  const isIndia = destinationPhone.slice(0, -10) == '+91'
  const paymentLink = isIndia ? "https://onroadlivetracking.mojo.page/onroadlivetracking" : "https://buy.stripe.com/9AQ8Axbeze1G7ao4gk"
  const google = window.google;
  const directionsService = new google.maps.DirectionsService();

  

  const mapRef = React.useRef(null);

  const useStyles = makeStyles((theme) =>
    createStyles({
      paper: {
        width: "100%",
        height: `calc(100vh - 60px)`,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative"
      },
      container: {
        // width: "100vw",
        // height: "100vh",
        height: `calc(100vh - 60px)`,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      messagesBody: {
        width: "calc( 100% - 20px )",
        margin: 10,
        overflowY: "scroll",
        height: "calc( 100% - 80px )"
      },
      root: {
        '&$selected': {
          color: "#e91e63",
        },
      },
      selected: {
        color: "#e91e63",
      }
    })
  );
  const classes = useStyles();


  useEffect(() => {
    if (deliveryGuyPhone && destinationPhone) {
      const onChildAdd = Firebase.database()
        .ref(`/activeincomingdeliveries/${destinationPhone}/${deliveryGuyPhone}`)
        .once('value', snapshot => {
          const data = snapshot.val()
          if (data && data.deliveryguylatlng) {
            setDeliveryGuyLocation(data.deliveryguylatlng)
          }
          if (data && data.addresslatlng) {
            setDestinationLocation(data.addresslatlng)
          }
          if (data && data.addressadjustedbycustomer) {
            setIsCustomerAdjustedLocation(data.addressadjustedbycustomer)
          }
          
        });
      return () => Firebase.database().ref(`/activeincomingdeliveries/${destinationPhone}/${deliveryGuyPhone}`).off('value', onChildAdd);
    }
  }, [])

  useEffect(() => {
    if (deliveryGuyPhone && destinationPhone) {
      const onChildAdd = Firebase.database()
        .ref(`/chat/${destinationPhone}/${deliveryGuyPhone}`)
        .limitToLast(100)
        .on('value', snapshot => {
          let result = [];
          snapshot.forEach(duckSnap => {
            const duck = duckSnap.val();
            result.push(duck);
          });

          if (result.length > 0) {
            setChatMessages(result);//.reverse());
          }
        });
      return () => Firebase.database().ref(`/chat/${destinationPhone}/${deliveryGuyPhone}`).off('value', onChildAdd);
    }
  }, [])

  useEffect(() => {
    if (deliveryGuyPhone) {
      const onChildAdd = Firebase.database()
        .ref(`/userlivelocationtracking/${deliveryGuyPhone}`)
        .on('value', snapshot => {
          const data = snapshot.val()
          if (data && data.latitude && data.longitude) {
            setDeliveryGuyLocationLiveTracking({
              lat: parseFloat(data.latitude),
              lng: parseFloat(data.longitude)
            })
            console.log("=====inside livetracking===", destinationLocation);
            if(destinationLocation) {
              directionsService.route(
                {
                  destination: {
                    lat: destinationLocation.lat,
                    lng: destinationLocation.lng,
                  },
                  origin: {
                    lat: data.latitude,
                    lng: data.longitude,
                  },
                  travelMode: "DRIVING",
                },
                (result, status) => {
                  if (status == "OK") {
                    setRemainingDistance(result.routes[0].legs[0].distance.text)
                    setRemainingTime(result.routes[0].legs[0].duration.text)
                  }
                })
            }
          }
        });
      // Stop listening for updates when no longer required
      return () => Firebase.database().ref(`/userlivelocationtracking/${deliveryGuyPhone}`).off('value', onChildAdd);
    }
  }, [destinationLocation]);

  useEffect(() => {
    if (deliveryGuyLocationLiveTracking && markerRef) {
      setTimeout(() => {
        markerRef.setPosition({
          lat: deliveryGuyLocationLiveTracking.lat,
          lng: deliveryGuyLocationLiveTracking.lng
        })
        // mapRef.panTo({lat: deliveryGuyLocationLiveTracking.lat,lng: deliveryGuyLocationLiveTracking.lng});
        // mapRef.setZoom(11);
        // animatingCamera(position.coords.latitude, position.coords.longitude, position.coords.heading);
        // getPointOnPath([position.coords.latitude, position.coords.longitude])

        // TODO 
        // mapRef.setHeading(45);
      }, 1000)
    }
  }, [deliveryGuyLocationLiveTracking])
  
  const mapStyles = {}
  const pinMarkerSVG =
  "M10 3.5C10 4.70948 9.14112 5.71836 8 5.94999V13.5C8 13.7761 7.77614 14 7.5 14C7.22386 14 7 13.7761 7 13.5V5.94999C5.85888 5.71836 5 4.70948 5 3.5C5 2.11929 6.11929 1 7.5 1C8.88071 1 10 2.11929 10 3.5Z";
  const squareMarkerSVG =
  "M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z";
  

  const recordReview = () => {
    Firebase.database()
        .ref(`/deliveryreview/${deliveryGuyPhone}/${destinationPhone}`)
        .set({
          "stars": deliveryReviewStars,
          "note": deliveryReviewNote
        })

    setRecorded(true);
  }
  const handleChange = (event) => {
    setDeliveryReviewNote(event.target.value);
  }

  const handleTip = (event) => {
    Firebase.database()
        .ref(`/deliverytip/${deliveryGuyPhone}/${destinationPhone}`)
        .set({
          "tip": 0,
          "time": `${new Date().toLocaleString()}`,
          "linkopened": true
        });
    return window.open(paymentLink, '_blank');
  }

  const SimpleBottomNavigation = () => {
    return (
      <BottomNavigation
        value={bottomTabValue}
        onChange={(event, newValue) => {
          setBottomTabValue(newValue);
        }}
        showLabels
        className={classes.bottomNavigation}
        
      >
        <BottomNavigationAction
          label="Shop"
          icon={<AddShoppingCartIcon />}
          classes={{
            root: classes.root,
            selected: classes.selected,
          }}
        />
        <BottomNavigationAction
          label="Live Track"
          icon={<PinDropSharpIcon />}
          classes={{
            root: classes.root,
            selected: classes.selected,
          }}
        />
        <BottomNavigationAction
          label="Tip"
          icon={<MonetizationOnSharpIcon />}
          classes={{
            root: classes.root,
            selected: classes.selected,
          }}
        />
        <BottomNavigationAction
          label="Chat"
          icon={<ChatSharpIcon />}
          classes={{
            root: classes.root,
            selected: classes.selected,
          }}
        />
        <BottomNavigationAction
          label="Review"
          icon={<GradeSharpIcon />}
          classes={{
            root: classes.root,
            selected: classes.selected,
          }}
        />
      </BottomNavigation>
    );
  };

  useEffect(() => {
    if(!deliveryGuyLocation || !destinationLocation){
      return ;
    }
    let map = mapRef.current;
    const myLatlng = new google.maps.LatLng(destinationLocation.lat, destinationLocation.lng);
    const mapOptions = {
      zoom: 13,
      center: myLatlng,
      scrollwheel: false,
      mapTypeControl: false,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#d6e2e6",
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#cfd4d5",
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#7492a8",
            },
          ],
        },
        {
          featureType: "administrative.neighborhood",
          elementType: "labels.text.fill",
          stylers: [
            {
              lightness: 25,
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#dde2e3",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#cfd4d5",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#dde2e3",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#7492a8",
            },
          ],
        },
        {
          featureType: "landscape.natural.terrain",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#dde2e3",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.icon",
          stylers: [
            {
              saturation: -100,
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#588ca4",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#a9de83",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#bae6a1",
            },
          ],
        },
        {
          featureType: "poi.sports_complex",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#c6e8b3",
            },
          ],
        },
        {
          featureType: "poi.sports_complex",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#bae6a1",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [
            {
              saturation: -45,
            },
            {
              lightness: 10,
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#41626b",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#c1d1d6",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#a6b5bb",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#9fb6bd",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "labels.icon",
          stylers: [
            {
              saturation: -70,
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#b4cbd4",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#588ca4",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#008cb5",
            },
          ],
        },
        {
          featureType: "transit.station.airport",
          elementType: "geometry.fill",
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: -5,
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#a6cbe3",
            },
          ],
        },
      ],
    }
    map = new google.maps.Map(map, mapOptions);
    const pinMarker = new google.maps.Marker({
      position: myLatlng,
      icon: {
        path: pinMarkerSVG,
        fillColor: "#000000",
        fillOpacity: 1,
        strokeColor: "#000000",
        scale: 2,
        anchor: {
          x: 5,
          y: 13,
        },
      },
      map: map,
    });

    const myLatlng2 = new google.maps.LatLng(deliveryGuyLocation.lat, deliveryGuyLocation.lng);
    const squareMarker = new google.maps.Marker({
      position: myLatlng2,
      icon: {
        path: squareMarkerSVG,
        fillColor: "#000000",
        fillOpacity: 1,
        strokeColor: "#000000",
      },
      map: map,
    });

    const liveTrackMarker = new google.maps.Marker({
      position: myLatlng2,
      icon: {
        path: squareMarkerSVG,
        fillColor: "#e91e63",
        fillOpacity: 1,
        strokeColor: "#e91e63",
      },
      map: map,
    });

    setMarkerRef(liveTrackMarker);
    
    // const contentString =
    // '<div class="info-window-content"><h2>Material Dashboard PRO React</h2>' +
    // "<p>A premium Admin for React, Material-UI, and React Hooks.</p></div>";

    // const infowindow = new google.maps.InfoWindow({
    //   content: contentString,
    // });
    // google.maps.event.addListener(pinMarker, "click", function () {
    //   infowindow.open(map, pinMarker);
    // });
    // google.maps.event.addListener(squareMarker, "click", function () {
    //   infowindow.open(map, squareMarker);
    // });

    // setLiveTrackMapRef(new google.maps.Marker({
    //   map: map,
    //   draggable: true
    // }));
    function autoRefresh(map, pathCoords) {
      var i, route;

      route = new google.maps.Polyline({
        path: [],
        geodesic: true,
        strokeColor: "#000000",
        strokeWeight: 5,
        editable: false,
        map: map,
      });

      for (i = 0; i < pathCoords.length; i++) {
        setTimeout(
          function (coords) {
            route.getPath().push(coords);
          },
          10 * i,
          pathCoords[i]
        );
      }
    }

    const directionsDisplay = new google.maps.DirectionsRenderer({
      map: map,
      suppressMarkers: true,
      // polylineOptions: {
      //   strokeWeight: 5,
      //   strokeColor: "#808080",
      // },
      preserveViewport: true,
    });

    let bounds = new google.maps.LatLngBounds();

    directionsService.route(
      {
        destination: {
          lat: destinationLocation.lat,
          lng: destinationLocation.lng,
        },
        origin: {
          lat: deliveryGuyLocation.lat,
          lng: deliveryGuyLocation.lng,
        },
        travelMode: "DRIVING",
      },
      (result, status) => {
        if (status == "OK") {
          // setLiveTrackMarkerRef(new google.maps.Marker({
          //   map: map
          // }));
          bounds.union(result.routes[0].bounds);
          map.fitBounds(bounds);

          var display = new google.maps.DirectionsRenderer({preserveViewport: true});

          display.setMap(map);
          directionsDisplay.setDirections(result);

          autoRefresh(map, result.routes[0].overview_path);
          setRemainingDistance(result.routes[0].legs[0].distance.text)
          setRemainingTime(result.routes[0].legs[0].duration.text)
        }
      }
    );
  },[deliveryGuyLocation,destinationLocation])


  const liveTrackPage = () => {
    if(deliveryGuyLocation && destinationLocation){
      return (
        <>
        <div
          style={{
            height: `calc(100vh - 60px)`,
            borderRadius: "6px",
            overflow: "hidden",
          }}
          ref={mapRef}
        >
        </div>
        <div style={{
            position: "absolute",
            top: "40px",  /* adjust value accordingly */
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontWeight: "bold"
        }}>
        <Card>
          <CardHeader>
          {remainingDistance} &nbsp; | &nbsp; {remainingTime}
           {/* &nbsp; | &nbsp; <SendIcon /> */}
          </CardHeader>
        </Card>
        </div>
        <div style={{
            position: "absolute",
            top: "83vh",  /* adjust value accordingly */
            left: "50%",
            transform: "translate(-50%, -50%)"
        }}>
        <Fab
          variant="extended"
          size="small"
          color="secondary"
          aria-label="add"
          className={classes.margin}
          onClick={()=>{
            window.open(`tel:${deliveryGuyPhone}`, '_self');
          }}
        >
          <CallIcon className={classes.extendedIcon} />
          Call
        </Fab>
        </div>

        </>
    );
    }
    return deliveryFinishedPage();
  }
  const deliveryFinishedPage = () => {
    return (
      <div className={classes.container}>
      <Paper className={classes.paper} zDepth={0} elevation={0} style={{
            textAlign: "center",
            justifyContent: 'center', //Centered horizontally
            alignItems: 'center', //Centered vertically
            flex:1
          }}>
      <p style={{color: "9e9e9e"}}>
          Delivery Completed
      </p>
      <h3 className={`${classes.cardTitle}`}>
        How's Delivery Experience
      </h3>
      <Rating
        name="hover-feedback"
        value={deliveryReviewStars}
        precision={0.5}
        onChange={(event, newValue) => {
          setDeliveryReviewStars(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        size="large"
        disabled={recorded}
      />
      {deliveryReviewStars !== null && <Box ml={2}>{labels[hover !== -1 ? hover : deliveryReviewStars]}</Box>}
      <TextField
          id="outlined-multiline-static"
          label="Thank You Note"
          multiline
          rows={4}
          // defaultValue="Thank you very much for the service"
          style={{
            marginTop: "100px",
            fontSize: "large"
          }}
          value={deliveryReviewNote}
          onChange={handleChange}
          disabled={recorded}
        />
        <Button
          round
          color={recorded ? "success": "rose"}
          style={{marginTop: "40px"}}
          onClick={() => recordReview()}
          disabled={recorded}
          startIcon={recorded ? <DoneIcon /> : null}
          >
          {recorded ? "Done": "Submit Review"}
        </Button>
        <br/>
        <small>
          Also, you can &nbsp;
          <Button color="primary" simple onClick={() => handleTip()} style={{
            padding: 0
          }}>
            Tip
          </Button>
          &nbsp;
          delivery personnel
        </small>
      </Paper>
    </div>
    )
  }

  const shopPage = () => {
    return (
      <>
        <div className={classes.container}>
         
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <Card product className={classes.cardHover}>
                  <CardHeader image className={classes.cardHeaderHover}>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img src={"https://www.partselect.com/assets/content/58702/laundry-cheat-sheet-open-graph.png"} alt="..." />
                    </a>
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardProductTitle}>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        5 Star Laundry Service
                      </a>
                    </h4>
                    <p className={classes.cardProductDesciprion}>
                      The place is close to Barceloneta Beach and bus stop just 2 min
                      by walk and near to {'"'}Naviglio{'"'} where you can enjoy the
                      main night life in Barcelona.
                    </p>
                  </CardBody>
                  <CardFooter product>
                    <div className={classes.price}>
                      <h4>$99/pickup</h4>
                    </div>
                    <div className={`${classes.stats} ${classes.productStats}`}>
                      <Place /> San Ramon, CA
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Card product className={classes.cardHover}>
                  <CardHeader image className={classes.cardHeaderHover}>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img src={"https://www.ocregister.com/wp-content/uploads/2020/10/OCR-L-AMAZON-FRESH-IRV-lede-PB-1.jpg"} alt="..." />
                    </a>
                  </CardHeader>
                  <CardBody>

                    <h4 className={classes.cardProductTitle}>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Grocery Store
                      </a>
                    </h4>
                    <p className={classes.cardProductDesciprion}>
                      The place is close to Metro Station and bus stop just 2 min by
                      walk and near to {'"'}Naviglio{'"'} where you can enjoy the
                      night life in London, UK.
                    </p>
                  </CardBody>
                  <CardFooter product>
                    <div className={classes.price}>
                      <h4>$100 Minimum</h4>
                    </div>
                    <div className={`${classes.stats} ${classes.productStats}`}>
                      <Place /> Dublin, CA
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Card product className={classes.cardHover}>
                  <CardHeader image className={classes.cardHeaderHover}>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img src={"https://www.franchisechatter.com/wp-content/uploads/2014/06/Jamba-Juice-Bar.jpg"} alt="..." />
                    </a>
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardProductTitle}>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Healthy Juice Shop
                      </a>
                    </h4>
                    <p className={classes.cardProductDesciprion}>
                      The place is close to Metro Station and bus stop just 2 min by
                      walk and near to {'"'}Naviglio{'"'} where you can enjoy the main
                      night life in Milan.
                    </p>
                  </CardBody>
                  <CardFooter product>
                    <div className={classes.price}>
                      <h4>20 Minimum</h4>
                    </div>
                    <div className={`${classes.stats} ${classes.productStats}`}>
                      <Place /> Danville, CA
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
        </div>
      </>
    )

  }
  const tipPage = () => {
    return (
      <div className={classes.container}>
      <Paper className={classes.paper} zDepth={0} elevation={0} style={{
            textAlign: "center",
            justifyContent: 'center', //Centered horizontally
            alignItems: 'center', //Centered vertically
            flex:1
          }}>
        <h3 className={`${classes.cardTitle}`} style={{width: "90vw"}}>
          Delivery personnel work really hard to get you the package
        </h3>
        <p style={{color: "9e9e9e"}}>
          We make sure tip goes to the delivery personnel
        </p>
        <Button
          round
          color="rose"
          // style={{marginTop: "70px"}}
          onClick={() => handleTip()}
          >
          Tip
        </Button>
      </Paper>
      </div>
    )
  }
  const sendChatMessage = (message) => {
    Firebase.database()
    .ref(`/chat/${destinationPhone}/${deliveryGuyPhone}`)
    .push({
      "message": message,
      "phone": destinationPhone
    })
  }
  const messageLeftOrRight = (val) => {
    if(val.phone === deliveryGuyPhone){
      return (<MessageLeft message={val.message}/>)
    }
    return (<MessageRight message={val.message}/>)
  }
  const chatPage = () => {
    return (<div className={classes.container}>
      <Paper className={classes.paper} zDepth={0} elevation={0}>
        <Paper id="style-1" className={classes.messagesBody} zDepth={0} elevation={0}>
          <MessageLeft
              message="Hi, how can I help you"
            />
          {chatMessages?.map(val => (
            messageLeftOrRight(val)
          ))}
        </Paper>
        {deliveryGuyLocation && destinationLocation ? (<TextInput backToApp={sendChatMessage}/>) : (<h3> Delivery completed </h3>)}
      </Paper>
    </div>)
  }
  const reviewPage = () => {
    return (
      <div className={classes.container}>
      <Paper className={classes.paper} zDepth={0} elevation={0} style={{
            textAlign: "center",
            justifyContent: 'center', //Centered horizontally
            alignItems: 'center', //Centered vertically
            flex:1
          }}>
      <h3 className={`${classes.cardTitle}`}>
        How's Delivery Experience
      </h3>
      <Rating
        name="hover-feedback"
        value={deliveryReviewStars}
        precision={0.5}
        onChange={(event, newValue) => {
          setDeliveryReviewStars(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        size="large"
        disabled={recorded}
      />
      {deliveryReviewStars !== null && <Box ml={2}>{labels[hover !== -1 ? hover : deliveryReviewStars]}</Box>}
      <TextField
          id="outlined-multiline-static"
          label="Thank You Note"
          multiline
          rows={4}
          // defaultValue="Thank you very much for the service"
          style={{
            marginTop: "100px",
            fontSize: "large"
          }}
          value={deliveryReviewNote}
          onChange={handleChange}
          disabled={recorded}
        />
        <Button
          round
          color={recorded ? "success": "rose"}
          style={{marginTop: "70px"}}
          onClick={() => recordReview()}
          disabled={recorded}
          startIcon={recorded ? <DoneIcon /> : null}
          >
          {recorded ? "Done": "Submit"}
        </Button>
  
      </Paper>
    </div>
    )
  }

  const renderBody = () => {
    switch(bottomTabValue) {
      case 0:
        return liveTrackPage();
      case 1:
        return shopPage();
      case 2:
        return tipPage();
      case 3:
        return chatPage();
      case 4:
        return reviewPage();
      default:
        return liveTrackPage();
    }
  }
  const homePage = () => {
    return (
      <>
        <div className={classes.container}>
        <Paper className={classes.paper} zDepth={0} elevation={0} style={{
            textAlign: "center",
            top: "40px",
            // justifyContent: 'center', //Centered horizontally
            // alignItems: 'center', //Centered vertically
            flex:1,
            maxHeight: 2000,
            overflow: 'auto'
          }}>
            <div style={{display: "flex",justifyContent: "flex-end", width: "90%"}} >
              <CardHeader color="success" text style={{width: "90%", padding: "5px"}}>
                <CardText color="success" style={{width: "90%"}}>
                  <h3 className={classes.cardTitleWhite} style={{padding: "5px", margin: "0px"}}>What is it </h3>
                  <h4 className={classes.cardCategoryWhite} style={{padding: "5px", margin: "0px"}}>
                    How it works ?
                  </h4>
                </CardText>
              </CardHeader>
            </div>
            
            <br />
            <CardHeader color="info" text style={{top: "10%"}}>
              <CardText color="info">
                <h3 className={classes.cardTitleWhite}>Order Laundry Pickup</h3>
              </CardText>
            </CardHeader>
            <br />
            <div style={{marginTop: "76px", textAlign: "left", width: "84%"}}>
            <h3 className={`${classes.cardTitle}`} style={{textAlign: "left"}}>
              Our Partners
            </h3>
            </div>
            <div style={{margin: "0px", padding: "0px", textAlign: "center"}}>
              <br />
              <GridContainer style={{margin: "0px", paddingLeft: "10px", textAlign: "center", width: "90%", marginLeft: "25px", width: "90%" }}>
                <GridItem xs={12} sm={12} md={4} style={{width: "80%", marginTop: "50px"}}>
                  <Card product className={classes.cardHover}>
                    <CardHeader image className={classes.cardHeaderHover}>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={"https://i1.wp.com/www.liveandwingit.com/wp-content/uploads/2020/06/DOWNLOAD-LAUNDRY-BUSINESS-PLAN-SAMPLE-WITH-FINANCIAL-ANALYSIS.jpg?fit=1024%2C512&ssl=1"} alt="..." />
                      </a>
                    </CardHeader>
                    <CardBody>
                      <h4 className={classes.cardProductTitle}>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Cozy 5 Stars Apartment
                        </a>
                      </h4>
                      <p className={classes.cardProductDesciprion}>
                        The place is close to Barceloneta Beach and bus stop just 2 min
                        by walk and near to {'"'}Naviglio{'"'} where you can enjoy the
                        main night life in Barcelona.
                      </p>
                    </CardBody>
                    <CardFooter product>
                      <div className={classes.price}>
                        <h4>$899/night</h4>
                      </div>
                      <div className={`${classes.stats} ${classes.productStats}`}>
                        <Place /> Barcelona, Spain
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} style={{width: "80%", marginTop: "50px"}}>
                  <Card product className={classes.cardHover}>
                    <CardHeader image className={classes.cardHeaderHover}>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={"https://i1.wp.com/www.liveandwingit.com/wp-content/uploads/2020/06/DOWNLOAD-LAUNDRY-BUSINESS-PLAN-SAMPLE-WITH-FINANCIAL-ANALYSIS.jpg?fit=1024%2C512&ssl=1"} alt="..." />
                      </a>
                    </CardHeader>
                    <CardBody>
                      <h4 className={classes.cardProductTitle}>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Office Studio
                        </a>
                      </h4>
                      <p className={classes.cardProductDesciprion}>
                        The place is close to Metro Station and bus stop just 2 min by
                        walk and near to {'"'}Naviglio{'"'} where you can enjoy the
                        night life in London, UK.
                      </p>
                    </CardBody>
                    <CardFooter product>
                      <div className={classes.price}>
                        <h4>$1.119/night</h4>
                      </div>
                      <div className={`${classes.stats} ${classes.productStats}`}>
                        <Place /> London, UK
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} style={{width: "80%", marginTop: "50px"}}>
                  <Card product className={classes.cardHover}>
                    <CardHeader image className={classes.cardHeaderHover}>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={"https://i1.wp.com/www.liveandwingit.com/wp-content/uploads/2020/06/DOWNLOAD-LAUNDRY-BUSINESS-PLAN-SAMPLE-WITH-FINANCIAL-ANALYSIS.jpg?fit=1024%2C512&ssl=1"} alt="..." />
                      </a>
                    </CardHeader>
                    <CardBody>
                      <h4 className={classes.cardProductTitle}>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Beautiful Castle
                        </a>
                      </h4>
                      <p className={classes.cardProductDesciprion}>
                        The place is close to Metro Station and bus stop just 2 min by
                        walk and near to {'"'}Naviglio{'"'} where you can enjoy the main
                        night life in Milan.
                      </p>
                    </CardBody>
                    <CardFooter product>
                      <div className={classes.price}>
                        <h4>$459/night</h4>
                      </div>
                      <div className={`${classes.stats} ${classes.productStats}`}>
                        <Place /> Milan, Italy
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
            
        </Paper>
        </div>
      </>
    )
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            {/* {renderBody()} */}
            {homePage()}
          </CardBody>
          {SimpleBottomNavigation()}
        </Card>
      </GridItem>
    </GridContainer>
  );
}
export default App;